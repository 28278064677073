import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface SingleChipSelectItem<T> {
  label: string;
  value: T;
  icon?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-single-chip-select',
  templateUrl: './single-chip-select.component.html',
  styleUrl: './single-chip-select.component.scss',
})
export class SingleChipSelectComponent<T> {
  @Input({ required: true }) options: SingleChipSelectItem<T>[] = [];

  @Input() value!: T;
  @Output() valueChange = new EventEmitter<T>();

  @Input() disabled: boolean = false;
  @Input() name!: string;

  constructor() {}

  selectItem(item: SingleChipSelectItem<T>) {
    if (this.disabled || item.disabled) {
      return;
    }
    this.value = item.value;
    this.valueChange.emit(this.value);
  }
}
