import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Column } from 'src/app/modules/shared/base/component-with-table/extended-table';

@Component({
  selector: 'app-multi-select-hidden-columns',
  templateUrl: './multi-select-hidden-columns.component.html',
  styleUrls: ['./multi-select-hidden-columns.component.scss'],
})
export class MultiSelectHiddenColumnsComponent {
  @Input() columns: Column[] = [];

  private _hiddenColumns!: Column[];
  @Input() table: string = '';

  @Input()
  set hiddenColumns(columns: Column[]) {
    if (this._hiddenColumns) this.hiddenColumnsChange.emit(columns);
    this._hiddenColumns = columns;
  }

  get hiddenColumns(): Column[] {
    return this._hiddenColumns;
  }

  get visibleHiddenColumns(): Column[] {
    return this._hiddenColumns.filter(x => x.key?.startsWith(`${this.table}_`));
  }

  @Output() hiddenColumnsChange: EventEmitter<Column[]> = new EventEmitter<
    Column[]
  >();

  @Input() showHiddenColumnLabels = false;

  isPanelOpen = false;
}
