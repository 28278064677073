import { Injectable } from '@angular/core';
import { QueryResult } from 'src/app/models/entities/query-result';
import { Team } from 'src/app/models/entities/team';
import { GET_ALL_TEAMS } from 'src/app/models/graphql/teams.query';
import { catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { RentalItem, RentalPackage } from 'src/app/models/entities/rentals';
import {
  GET_ALL_RENTAL_PACKAGES,
  GET_RENTAL_FAVORITE_ITEMS,
} from 'src/app/models/graphql/rentals/rental-packages.query';
import { ChipSelectItem } from 'src/app/modules/shared/components/chip-select/chip-select.component';
import { Bb } from 'src/app/models/entities/bb';
import { GET_BBS } from 'src/app/models/graphql/bbs.query';
import { map, Observable } from 'rxjs';
import { Role } from 'src/app/models/entities/role';
import { GET_ROLES } from 'src/app/models/graphql/roles.query';
import { GameDay } from 'src/app/models/entities/game-day';
import {
  GET_ACTIVE_GAME_DAY,
  GET_ALL_GAME_DAYS,
} from 'src/app/models/graphql/game-days.query';
import { GET_ALL_PLAYERS } from 'src/app/models/graphql/players.query';
import { Player } from 'src/app/models/entities/player';
import { RfidTag } from 'src/app/models/entities/rfid';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category, Preset } from 'src/app/models/entities/shopping-list';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient
  ) {}

  getApiVersion(): Observable<string> {
    return this.http.get(`${environment.api.url}/api/version`, {
      responseType: 'text',
    });
  }

  getActiveGameDay(): Observable<GameDay | undefined> {
    return this.apollo
      .query<{
        gameDays: QueryResult<GameDay>;
      }>({
        query: GET_ACTIVE_GAME_DAY,
      })
      .pipe(
        map(response => {
          if (!response.data.gameDays?.nodes) {
            return undefined;
          }
          if (response.data.gameDays.nodes.length === 0) {
            return undefined;
          }

          return response.data.gameDays.nodes[0];
        }),
        catchError(err => {
          throw err;
        })
      );
  }

  getGameDays(): Observable<GameDay[]> {
    return this.apollo
      .query<{
        gameDays: QueryResult<GameDay>;
      }>({
        query: GET_ALL_GAME_DAYS,
      })
      .pipe(
        map(response => response.data.gameDays.nodes),
        catchError(err => {
          throw err;
        })
      );
  }

  getPlayers(): Observable<Player[]> {
    return this.apollo
      .query<{
        players: QueryResult<Player>;
      }>({
        query: GET_ALL_PLAYERS,
      })
      .pipe(
        map(response => response.data.players.nodes),
        catchError(err => {
          throw err;
        })
      );
  }

  getTeams(): Observable<Team[]> {
    return this.apollo
      .query<{
        teams: QueryResult<Team>;
      }>({
        query: GET_ALL_TEAMS,
      })
      .pipe(
        map(response => response.data.teams.nodes),
        catchError(err => {
          throw err;
        })
      );
  }

  getRentalPackagesAndItemsAsChipSelectItems(): Observable<ChipSelectItem[]> {
    return this.apollo
      .query<{
        rental: {
          packages: QueryResult<RentalPackage>;
          items: QueryResult<RentalItem>;
        };
      }>({
        query: GET_ALL_RENTAL_PACKAGES,
      })
      .pipe(
        map(response => [
          ...response.data.rental.packages.nodes.map(x => {
            return { label: x.name, value: x, icon: 'pi pi-tags' };
          }),
          ...response.data.rental.items.nodes.map(x => {
            return {
              label: x.name,
              value: x,
              icon: 'pi pi-tag',
              multiple: true,
            };
          }),
        ]),
        catchError(err => {
          throw err;
        })
      );
  }

  getRentalItemsAsChipSelectItems(): Observable<ChipSelectItem[]> {
    return this.apollo
      .query<{
        rental: {
          items: QueryResult<RentalItem>;
        };
      }>({
        query: GET_ALL_RENTAL_PACKAGES,
      })
      .pipe(
        map(response =>
          response.data.rental.items.nodes.map(x => {
            return {
              label: x.name,
              value: x,
              icon: 'pi pi-tag',
              multiple: true,
            };
          })
        ),
        catchError(err => {
          throw err;
        })
      );
  }

  getFavoriteRentalItemsAsChipSelectItems(): Observable<ChipSelectItem[]> {
    return this.apollo
      .query<{
        rental: {
          items: QueryResult<RentalItem>;
        };
      }>({
        query: GET_RENTAL_FAVORITE_ITEMS,
      })
      .pipe(
        map(response =>
          response.data.rental.items.nodes.map(x => {
            return { label: x.name, value: x, icon: 'pi pi-tag' };
          })
        ),
        catchError(err => {
          throw err;
        })
      );
  }

  getBBWeightsAsChipSelectItems(
    withName: boolean = false
  ): Observable<ChipSelectItem[]> {
    return this.apollo
      .query<{
        shop: {
          bbs: {
            bbs: QueryResult<Bb>;
          };
        };
      }>({
        query: GET_BBS,
        variables: {
          filter: [],
          sort: [],
          skip: 0,
          take: 99999,
        },
      })
      .pipe(
        map(response =>
          response.data.shop.bbs.bbs.nodes.map(x => {
            return {
              label: withName ? `${x.name} ${x.weight}` : x.weight.toString(),
              value: x.weight,
              icon: 'pi pi-spinner-dotted',
              multiple: true,
            };
          })
        ),
        catchError(err => {
          throw err;
        })
      );
  }

  getFavoriteBBWeightsAsChipSelectItems(
    withName: boolean = false
  ): Observable<ChipSelectItem[]> {
    return this.apollo
      .query<{
        shop: {
          bbs: {
            bbs: QueryResult<Bb>;
          };
        };
      }>({
        query: GET_BBS,
        variables: {
          filter: [{ favorite: { equal: true } }],
          sort: [],
          skip: 0,
          take: 99999,
        },
      })
      .pipe(
        map(response =>
          response.data.shop.bbs.bbs.nodes.map(x => {
            return {
              label: withName ? `${x.name} ${x.weight}` : x.weight.toString(),
              value: x.weight,
              icon: 'pi pi-spinner-dotted',
              multiple: true,
            };
          })
        ),
        catchError(err => {
          throw err;
        })
      );
  }

  getRolesAsChipSelectItems(): Observable<ChipSelectItem[]> {
    return this.apollo
      .query<{ permissions: { roles: QueryResult<Role> } }>({
        query: GET_ROLES,
        variables: {
          filter: [],
          sort: [],
          skip: 0,
          take: 99999,
        },
      })
      .pipe(
        map(response =>
          response.data.permissions.roles.nodes.map(x => {
            return {
              label: x.name ?? '',
              value: x,
            };
          })
        ),
        catchError(err => {
          throw err;
        })
      );
  }

  findPlayerByRFIDTag(rfid: string): Observable<Player | undefined> {
    return this.apollo
      .query<{
        rfidTags: QueryResult<RfidTag>;
      }>({
        query: gql`
          query ($tagId: String) {
            rfidTags(filter: { tagId: { contains: $tagId } }) {
              totalCount
              count
              nodes {
                id
                tagId
                player {
                  id
                  callSign
                }
                active
              }
            }
          }
        `,
        variables: {
          id: rfid,
        },
      })
      .pipe(
        map(response => {
          if (response.data.rfidTags.nodes.length === 0) {
            return undefined;
          }

          return response.data.rfidTags.nodes[0].player;
        }),
        catchError(err => {
          throw err;
        })
      );
  }

  getShoppingListCategory(): Observable<Category[]> {
    return this.apollo
      .query<{
        shoppingList: {
          categories: QueryResult<Category>;
        };
      }>({
        query: gql`
          query {
            shoppingList {
              categories {
                nodes {
                  id
                  name
                }
              }
            }
          }
        `,
      })
      .pipe(
        map(response => response.data.shoppingList.categories.nodes),
        catchError(err => {
          throw err;
        })
      );
  }

  getShoppingListPresets(): Observable<Preset[]> {
    return this.apollo
      .query<{
        shoppingList: {
          presets: QueryResult<Preset>;
        };
      }>({
        query: gql`
          query {
            shoppingList {
              presets {
                nodes {
                  id
                  name
                  category {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
      })
      .pipe(
        map(response => response.data.shoppingList.presets.nodes),
        catchError(err => {
          throw err;
        })
      );
  }
}
