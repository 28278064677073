<p-dialog
  styleClass="custom-dialog"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '50vw', heigth: '75vh' }">
  <ng-template pTemplate="header">
    <h1>Dev console</h1>
  </ng-template>
  <div>
    <p-terminal [prompt]="prompt" [welcomeMessage]="welcomeMessage" />
  </div>
</p-dialog>
