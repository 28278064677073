import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TileSelectItem } from 'src/app/modules/shared/components/tile-select/tile-select.component';
import { MultiSelect, MultiSelectChangeEvent } from 'primeng/multiselect';
import { TranslateService } from '@ngx-translate/core';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface ChipSelectItem {
  label: string;
  value: any;
  icon?: string;
  count?: number;
  multiple?: boolean;
}

@Component({
  selector: 'app-chip-select',
  templateUrl: './chip-select.component.html',
  styleUrl: './chip-select.component.scss',
})
export class ChipSelectComponent {
  @ViewChild('ms') ms!: MultiSelect;
  @Input() name: string = 'chip-select';
  @Input() addDialogHeaderTKey: string = 'common.unknown';
  @Input() addDropdownPlaceholderTKey: string = 'common.unknown';
  @Input() optionLabel: string | undefined = 'label';
  private _items: ChipSelectItem[] | undefined = [];
  @Input({ required: true })
  get items(): ChipSelectItem[] | undefined {
    return this._items;
  }

  set items(items: ChipSelectItem[] | undefined) {
    this._items = items;
    if (!items) {
      return;
    }

    items = this._selectedItemsValues
      .map(x =>
        this.optionLabel !== undefined ? x[this.optionLabel] : x.toString()
      )
      .map(x => items?.find(i => i.label === x)) as ChipSelectItem[];
    this._selectedItems = this.mapValuesToItems(items);
    this._selectedItemsValues = this._selectedItems.map(x => x.value);
  }

  @Input() quickSelectionItems!: TileSelectItem[] | undefined;

  @Input({ required: true })
  set selectedItems(items: any[] | undefined) {
    if (!items) {
      return;
    }
    this._selectedItems = this.mapValuesToItems(items);
    this._selectedItemsValues = items;
    this.selectedItemsChange.emit(this._selectedItemsValues);
  }

  get selectedItems(): any[] {
    return this._selectedItemsValues;
  }

  @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();

  @Input()
  set quickSelectItems(items: any[]) {
    if (!items) {
      return;
    }
    this._quickSelectItems = this.mapValuesToItems(items);
    this._quickSelectItemsValues = items;
  }

  get quickSelectItems(): any[] {
    return this._quickSelectItemsValues;
  }

  @Input() disabled: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onShow: EventEmitter<void> = new EventEmitter<void>();

  isAddDialogVisible = false;
  newItems: any[] | undefined;

  _selectedItems: ChipSelectItem[] = [];
  _selectedItemsValues: any[] = [];

  _quickSelectItems: TileSelectItem[] = [];
  _quickSelectItemsValues: any[] = [];
  selectedItemsLabel: string = this.t.instant('chip_select.x_items_selected');

  constructor(private t: TranslateService) {}

  private mapValuesToItems(values: any[]): ChipSelectItem[] {
    return values.map(x => {
      const defaultItem = {
        label: this.optionLabel ? x[this.optionLabel] : x,
        value: x,
      };

      if (!this.items) {
        return defaultItem;
      }
      return (
        this.items.find(
          y =>
            y === x ||
            (this.optionLabel !== undefined ? x[this.optionLabel] : x) ==
              y.label
        ) ?? defaultItem
      );
    });
  }

  filterSelectedItemsDuplicates(items: any[]): { count: number; item: any }[] {
    const counts: number[] = [];
    const filteredItems: any[] = [];

    items.forEach(item => {
      if (!filteredItems.includes(item)) {
        filteredItems.push(item);
        counts.push(1);
      } else {
        counts[filteredItems.indexOf(item)]++;
      }
    });

    return filteredItems.map((item, index) => {
      return {
        count: counts[index],
        item: item,
      };
    });
  }

  itemClick(event: MultiSelectChangeEvent) {
    if (!event.value.includes(event.itemValue)) {
      event.itemValue.count = undefined;
      this.removeAdditionalItem(event.itemValue);
      return;
    }
    event.itemValue.count = 1;
  }

  removeItem(item: any): void {
    this._selectedItemsValues.splice(
      this._selectedItemsValues.indexOf(item),
      1
    );
    this.selectedItemsChange.emit(this._selectedItemsValues);
  }

  addItem() {
    this.onShow.emit();
    this.resetTileSelect();
    this.isAddDialogVisible = true;
  }

  addAdditionalItem(event: MouseEvent, item: ChipSelectItem) {
    event.stopPropagation();
    if (!this.newItems) {
      this.newItems = [];
    }

    if (item.count === undefined) {
      item.count = 0;
    }

    item.count++;
    this.newItems.push(item);
  }

  removeAdditionalItemEvent(event: MouseEvent, item: ChipSelectItem) {
    event.stopPropagation();
    this.removeAdditionalItem(item);
  }

  removeAdditionalItem(item: ChipSelectItem) {
    if (!this.newItems) {
      this.newItems = [];
    }

    if (!item.count) {
      return;
    }
    item.count--;

    if (item.count === -1) {
      item.count = undefined;
      return;
    }
    this.newItems.splice(this.newItems.indexOf(item), 1);
  }

  saveAddItem() {
    this.isAddDialogVisible = false;

    this._quickSelectItems.forEach(x => {
      this._selectedItems.push({
        label: x.label,
        value: x.value,
        icon: x.icon,
      });
    });
    this._quickSelectItemsValues.forEach(x => {
      this._selectedItemsValues.push(x);
    });

    if (!this.newItems) {
      return;
    }

    this.newItems.forEach(x => {
      this._selectedItems.push(x);
      this._selectedItemsValues.push(x.value);
    });
    this.selectedItemsChange.emit(this._selectedItemsValues);
    this.newItems = undefined;
  }

  resetTileSelect() {
    this.newItems = [];
    if (!this.items) {
      return;
    }
    this.items.forEach(x => {
      x.count = undefined;
    });

    this._quickSelectItems = [];
    this._quickSelectItemsValues = [];
    if (!this.quickSelectionItems) {
      return;
    }
    this.quickSelectionItems = this.quickSelectionItems.map(x => {
      return {
        label: x.label,
        value: x.value,
        icon: x.icon,
      };
    });
  }
}
