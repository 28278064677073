import { Component } from '@angular/core';
import { Player } from 'src/app/models/entities/player';
import { PlayerProfileDialogService } from 'src/app/modules/shared/services/player-profile-dialog/player-profile-dialog.service';

@Component({
  selector: 'app-player-profile-dialog',
  templateUrl: './player-profile-dialog.component.html',
  styleUrl: './player-profile-dialog.component.scss',
})
export class PlayerProfileDialogComponent {
  player: Player | undefined;

  get visible() {
    return this.player !== undefined;
  }

  set visible(value: boolean) {
    if (!value) {
      this.player = undefined;
    }
  }

  constructor(private playerProfileDialogService: PlayerProfileDialogService) {
    this.playerProfileDialogService.player$.subscribe(player => {
      this.player = player;
    });
  }
}
