import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SelectedItemsService } from 'src/app/modules/shared/select-items/selected-items.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[selectable]',
})
export class SelectableDirective {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @Input({ required: true }) selectable: any;

  private ctrlPressed = false;

  constructor(
    private selectionService: SelectedItemsService,
    private el: ElementRef
  ) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.ctrlPressed = true;
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.ctrlPressed = false;
    }
  }

  @HostListener('click')
  handleClick() {
    if (this.selectionService.items.includes(this.selectable)) {
      this.selectionService.remove(this.selectable, this.el.nativeElement);
      return;
    }

    if (this.ctrlPressed) {
      this.selectionService.add(this.selectable, this.el.nativeElement);
      return;
    }

    this.selectionService.clear();
    this.selectionService.add(this.selectable, this.el.nativeElement);
  }
}
