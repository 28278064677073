import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  TableColumn,
  TableColumnSize,
  TableColumnType,
  TableRequiredPermissions,
} from 'src/app/modules/shared/components/table/table.model';
import { Column } from 'src/app/modules/shared/base/component-with-table/extended-table';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, Observable, of, Subject, take } from 'rxjs';
import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Sort, SortOrder } from 'src/app/models/graphql/filter/sort.model';
import { FormControl, FormGroup } from '@angular/forms';
import { QueryResult } from 'src/app/models/entities/query-result';
import { AddActionDirective } from 'src/app/modules/shared/components/table/add-action.directive';
import { EditActionDirective } from 'src/app/modules/shared/components/table/edit-action.directive';
import { DeleteActionDirective } from 'src/app/modules/shared/components/table/delete-action.directive';
import { MenuItem } from 'primeng/api';
import { CustomActionsDirective } from 'src/app/modules/shared/components/table/custom-actions.directive';
import { CustomColumnDirective } from 'src/app/modules/shared/components/table-column/custom-column.directive';
import { IdentityService } from 'src/app/services/identity/identity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalSettingsService } from 'src/app/modules/shared/services/personal-settings.service';
import { ContextMenu } from 'primeng/contextmenu';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
export class TableComponent<T> implements OnInit, OnDestroy {
  @ViewChild('dt') dt!: Table;
  @ViewChild('cm') contextMenuElement!: ContextMenu;

  @Input({ required: true }) name!: string;

  @Input() dataKey: string = 'id';
  @Input({ required: true }) result!: QueryResult<T>;
  @Input({ required: true }) countHeaderTranslation!: string;
  @Input({ required: true }) columns!: TableColumn[];
  @Input({ required: true }) requiredPermissions!: TableRequiredPermissions;

  @Input() hidePaginator?: boolean;
  @Input() contextMenu?: MenuItem[];

  protected _contextMenuItem!: T;
  set contextMenuItem(value: T) {
    this._contextMenuItem = value;
    this.openContextMenu.emit();
  }

  get contextMenuItem() {
    return this._contextMenuItem;
  }

  @Input() defaultFilter: Filter[] = [];
  @Input() filterInterceptors?: ((
    attribute: string,
    operator: string,
    value: string
  ) => Filter | undefined)[];
  @Input() sortInterceptors?: ((
    field: string,
    value: string
  ) => Sort | undefined)[];
  @Input() newNodeTemplate?: T = {} as T;
  @Input() rowDisabled?: (item: T) => boolean;

  @Input() onRowClick?: (item: T) => void;
  @Input() rowRouterLink?: (item: T) => string;

  @Input() responsiveLayout: 'stack' | 'scroll' = 'stack';

  @Output() reloadData: EventEmitter<void> = new EventEmitter<void>();
  @Output() columnHide: EventEmitter<Column[]> = new EventEmitter<Column[]>();
  @Output() add: EventEmitter<T> = new EventEmitter<T>();
  @Output() edit: EventEmitter<T> = new EventEmitter<T>();
  @Output() delete: EventEmitter<T> = new EventEmitter<T>();
  @Output() openContextMenu: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetFilterEvent: EventEmitter<void> = new EventEmitter<void>();

  private _hiddenColumns: Column[] = [];
  set hiddenColumns(value: Column[]) {
    this._hiddenColumns = value;
    this.settings.setSetting(`hiddenColumns.${this.name}`, value);
    this.columnHide.emit(value);
  }

  get hiddenColumns(): Column[] {
    return this._hiddenColumns;
  }

  protected get hideableColumns(): Column[] {
    return this.columns
      .filter(column => column.hideable)
      .map(x => {
        return {
          key: this.getKey(x.label),
          name: x.label,
        };
      });
  }

  protected get editable() {
    return this.columns.some(column => column.editable);
  }

  protected get sortable() {
    return this.columns.some(column => column.sortable);
  }

  protected get filterable() {
    return this.columns.some(column => column.filterable);
  }

  protected loading: boolean = false;
  protected rowsPerPageOptions = [25, 50, 100, 250, 500];
  public skip = 0;
  public take = 50;
  public sort: Sort[] = [];
  protected sortField: string = '';
  protected sortOrder: number = -1;
  public filter: Filter[] = [];
  protected queryFilter: { [key: string]: any }[] = [];
  protected filterForm!: FormGroup;
  protected defaultFilterForm!: FormGroup;

  public isEditingNew: boolean = false;
  public isEditing: boolean = false;

  private clonedNodes: T[] = [];
  public editingNode?: T;

  protected unsubscriber = new Subject<void>();

  protected hasPermissions = {
    add: true,
    edit: true,
    delete: true,
  };

  protected dropdownOptions: { [key: string]: Observable<any> } = {};

  @ContentChild(CustomActionsDirective, { read: TemplateRef })
  customActionTemplate!: TemplateRef<any>;
  @ContentChild(AddActionDirective, { read: TemplateRef })
  addActionTemplate!: TemplateRef<any>;
  @ContentChild(EditActionDirective, { read: TemplateRef })
  editActionTemplate!: TemplateRef<any>;
  @ContentChild(DeleteActionDirective, { read: TemplateRef })
  deleteActionTemplate!: TemplateRef<any>;
  @ContentChild(CustomColumnDirective, { read: TemplateRef })
  customColumnTemplate!: TemplateRef<{
    $implicit: T;
    column: TableColumn;
  }>;

  constructor(
    private identity: IdentityService,
    private route: ActivatedRoute,
    private router: Router,
    private settings: PersonalSettingsService
  ) {}

  async ngOnInit() {
    this.buildDefaultFilterForm();
    this.setDefaultFilterForm();
    this.filter = this.defaultFilter;
    this.buildFilterByQueryParams();
    this.setFilterForm();

    const user = await this.identity.getLoggedInUser();
    if (this.requiredPermissions.add) {
      this.hasPermissions.add = this.identity.hasUserPermission(
        user,
        this.requiredPermissions.add
      );
    }
    if (this.requiredPermissions.edit)
      this.hasPermissions.edit = this.identity.hasUserPermission(
        user,
        this.requiredPermissions.edit
      );
    if (this.requiredPermissions.delete)
      this.hasPermissions.delete = this.identity.hasUserPermission(
        user,
        this.requiredPermissions.delete
      );

    const hiddenColumns = await this.settings.getSetting(
      `hiddenColumns.${this.name}`
    );
    if (hiddenColumns) {
      this.hiddenColumns = hiddenColumns;
    }
    this.columns.forEach(c => {
      if (c.initialHidden) {
        this.hiddenColumns.push({ key: this.getKey(c.label), name: c.label });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  protected setQueryParams() {
    this.route.paramMap.pipe(take(1)).subscribe(params => {
      if (
        params.get('skip') !== this.skip.toString() ||
        params.get('take') !== this.take.toString() ||
        params.get('sort') !== JSON.stringify(this.sort) ||
        params.get('filter') !== JSON.stringify(this.queryFilter)
      )
        this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: {
              skip: this.skip,
              take: this.take,
              sort:
                this.sort.length > 0 ? JSON.stringify(this.sort) : undefined,
              filter:
                this.queryFilter.length > 0
                  ? JSON.stringify(this.queryFilter)
                  : undefined,
            },
            queryParamsHandling: 'merge', // remove to replace all query params by provided
            replaceUrl: true,
          })
          .then();
    });
  }

  protected buildFilterByQueryParams() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(params => {
        if ('skip' in params) {
          this.skip = +params['skip'];
        }
        if ('take' in params) {
          this.take = +params['take'];
        }
        if ('sort' in params) {
          this.sort = JSON.parse(params['sort']);
          this.applySortToTable();
        }
        if ('filter' in params) {
          this.queryFilter = JSON.parse(params['filter']);
        }
      });
  }

  protected applySortToTable() {
    if (!(this.sort && this.sort.length > 0)) {
      return;
    }

    const sortEntry = this.sort[0];
    const c = this.columns.find(
      x =>
        x.label === Object.keys(sortEntry)[0] ||
        x.name === Object.keys(sortEntry)[0]
    );
    if (!c) {
      return;
    }
    this.sortField = c.label;
    const sortValue = sortEntry[c.name];
    this.sortOrder =
      sortValue === SortOrder.ASC ? 1 : sortValue === SortOrder.DESC ? -1 : 0;
  }

  public buildDefaultFilterForm() {
    this.defaultFilterForm = new FormGroup({});
    this.columns
      .filter(x => x.filterable)
      .forEach(x => {
        let control!: FormControl;

        if (x.fuzzyFilterColumns) {
          control = new FormControl<string | null>(null);
          this.defaultFilterForm.addControl('fuzzy', control);
          return;
        } else if (
          x.type === TableColumnType.DROPDOWN &&
          x.dropdownOptions &&
          x.dropdownOptions.multiple
        ) {
          control = new FormControl<[] | null>(null);
        } else if (x.type === TableColumnType.STRING) {
          control = new FormControl<string | null>(null);
        } else if (x.type === TableColumnType.NUMBER) {
          control = new FormControl<number | null>(null);
        } else if (x.type === TableColumnType.BOOLEAN) {
          control = new FormControl<boolean | null>(null);
        } else if (x.type === TableColumnType.DATE) {
          control = new FormControl<Date | null>(null);
        } else {
          control = new FormControl<any | null>(null);
        }
        this.defaultFilterForm.addControl(x.name, control);
      });
  }

  public setDefaultFilterForm() {
    this.defaultFilter.forEach(x => {
      Object.keys(x).forEach(key => {
        const value = x[key];
        if (!(key in this.defaultFilterForm.controls)) {
          return;
        }
        if (typeof value === 'object' && value !== null) {
          Object.keys(value).forEach(subKey => {
            this.defaultFilterForm.get([key])?.setValue(value[subKey]);
          });
        } else {
          this.defaultFilterForm.get(key)?.setValue(value);
        }
      });
    });
  }

  public setFilterForm() {
    this.filterForm = this.defaultFilterForm;

    this.filterForm.valueChanges
      .pipe(takeUntil(this.unsubscriber), debounceTime(600))
      .subscribe(changes => {
        if (this.filterForm.disabled) {
          return;
        }

        this.queryFilter = [];
        this.filter = [];
        for (const atr in changes) {
          let value = changes[atr];

          if (value !== '' && value !== null && value !== undefined) {
            let operator = 'contains';

            if (Array.isArray(value)) {
              operator = 'in';
            } else if (typeof value === 'boolean') {
              operator = 'equal';
            } else if (typeof value === 'number' || this.isNumeric(value)) {
              operator = 'equal';
              value = +value;
            }

            this.queryFilter.push({ [atr]: value });

            const interceptedFilters: Filter[] = [];
            if (this.filterInterceptors) {
              this.filterInterceptors.forEach(x => {
                const interceptedFilter = x(atr, operator, value);
                if (!interceptedFilter) return;
                interceptedFilters.push(interceptedFilter);
              });
            }

            if (interceptedFilters.length > 0) {
              interceptedFilters.forEach(x => {
                this.filter.push(x);
              });
            } else {
              const filter: Filter = {};
              filter[atr] = {};
              filter[atr][operator] = value;

              this.filter.push(filter);
            }
          }
        }

        this.setQueryParams();
        this.reloadData.emit();
      });

    this.queryFilter.forEach(filter => {
      Object.keys(filter).forEach(key => {
        // preload hardcoded filter
        if (key === 'team') {
          const c = this.columns.find(x => x.name === 'team');
          if (c) this.openDropdown(c);
        }
        if (key === 'attendance') {
          const c = this.columns.find(x => x.name === 'team');
          if (c) this.openDropdown(c);
        }
        this.filterForm.controls[key].setValue(filter[key]);
      });
    });
  }

  private isNumeric(val: string): boolean {
    return !isNaN(Number(val));
  }

  public resetFilters(): void {
    this.filterForm.reset();
    this.setDefaultFilterForm();
    this.resetFilterEvent.emit();
  }

  public resetSort(table: Table): void {
    this.sort = [];
    table.reset();
  }

  public nextPage(event: TableLazyLoadEvent): void {
    this.take = event.rows ?? 10;
    this.skip = event.first ?? 0;

    if (event.sortField) {
      const field = event.sortField.toString().toLowerCase().split('.')[0];
      const value =
        event.sortOrder === 1
          ? SortOrder.ASC
          : event.sortOrder === -1
            ? SortOrder.DESC
            : SortOrder.ASC;

      this.sort = [];
      const interceptedSorts: Sort[] = [];
      if (this.sortInterceptors) {
        this.sortInterceptors.forEach(x => {
          const interceptedSort = x(field, value);
          if (!interceptedSort) return;
          interceptedSorts.push(interceptedSort);
        });
      }

      if (interceptedSorts.length > 0) {
        interceptedSorts.forEach(x => {
          this.sort.push(x);
        });
      } else {
        const sort: Sort = {};
        sort[field] = value;

        this.sort.push(sort);
      }
    }

    this.setQueryParams();
    this.reloadData.emit();
  }

  public addNewNode() {
    const newNode = JSON.parse(JSON.stringify(this.newNodeTemplate ?? {}));
    this.result.nodes = [newNode, ...this.result.nodes];

    this.dt.initRowEdit(newNode);
    this.onRowEditInit(newNode, this.result.nodes.length);
    this.isEditingNew = true;
    this.filterForm.disable();
  }

  public onRowEditInit(object: T, index: number): void {
    this.isEditing = true;
    this.clonedNodes[index] = { ...object };
    this.editingNode = object;
  }

  public onRowEditCancel(index: number): void {
    this.isEditing = false;
    this.filterForm.enable();
    if (this.isEditingNew) {
      this.result.nodes.splice(index, 1);
      delete this.clonedNodes[index];
      this.isEditingNew = false;
      this.editingNode = undefined;
      return;
    }

    this.result.nodes[index] = this.clonedNodes[index];
    delete this.clonedNodes[index];
    this.editingNode = undefined;
  }

  public onRowEditSave(node: T, index: number) {
    if (
      this.isEditingNew &&
      JSON.stringify(node) === (this.newNodeTemplate ?? ({} as T))
    ) {
      this.isEditingNew = false;
      this.result.nodes.splice(index, 1);
      this.editingNode = undefined;
      return;
    }
    this.isEditing = false;

    if (this.isEditingNew) {
      this.add.emit(node);
      this.isEditingNew = false;
      this.editingNode = undefined;
      return;
    }
    this.edit.emit(node);
    this.editingNode = undefined;
  }

  private getKey(column: string): string {
    return `${this.name}_${column}`;
  }

  public isColumnVisible(column: string): boolean {
    return !this._hiddenColumns
      .map(column => column.key)
      .includes(this.getKey(column));
  }

  protected getFuzzyColspan(columns: string[]): number {
    return this.columns.filter(
      x => columns.includes(x.label) && this.isColumnVisible(x.label)
    ).length;
  }

  protected openDropdown(column: TableColumn) {
    const fallbackOptions = column.dropdownOptions?.options ?? [];

    if (!column.dropdownOptions?.optionGetter) {
      this.dropdownOptions[column.name] = of(fallbackOptions);
      return;
    }
    this.dropdownOptions[column.name] = column.dropdownOptions.optionGetter();
  }

  protected readonly TableColumnType = TableColumnType;
  protected readonly TableColumnSize = TableColumnSize;
}
