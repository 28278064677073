<div class="flex h-full items-center gap-5">
  <input
    pInputText
    type="tel"
    maxlength="1"
    [id]="name + 0"
    [name]="name"
    [attr.value]="valueInput[0]"
    (keydown)="onInput2(0, $event)"
    [ngStyle]="{ color: pickerColor }"
    style="width: 70px; font-size: 2rem"
    class="h-full"
    [required]="required"
    [disabled]="disabled" />
  ,
  <input
    pInputText
    type="tel"
    maxlength="1"
    [id]="name + 1"
    [name]="name"
    [attr.value]="valueInput[1]"
    (keydown)="onInput2(1, $event)"
    [ngStyle]="{ color: pickerColor }"
    style="width: 70px; font-size: 2rem"
    class="h-full"
    [required]="required"
    [disabled]="disabled" />
  <input
    pInputText
    type="tel"
    maxlength="1"
    [id]="name + 2"
    [name]="name"
    [attr.value]="valueInput[2]"
    (keydown)="onInput2(2, $event)"
    [ngStyle]="{ color: pickerColor }"
    style="width: 70px; font-size: 2rem"
    class="h-full"
    [required]="required"
    [disabled]="disabled" />
</div>
