import { gql } from 'apollo-angular';

export const GET_USERS = gql`
  query users(
    $filter: [UserFilter!]!
    $sort: [UserSort!]!
    $skip: Int
    $take: Int
  ) {
    users(filter: $filter, skip: $skip, sort: $sort, take: $take) {
      totalCount
      count
      nodes {
        identityId
        username
        email
        roles {
          id
          name
        }
      }
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  query {
    user {
      identityId
      username
      email
      roles {
        id
        name
        permissions {
          id
          name
        }
      }
    }
  }
`;

export const ADD_USER_ROLES = gql`
  mutation ($userIdentityId: Guid!, $roleIds: [Int!]!) {
    administration {
      setUserRoles(userIdentityId: $userIdentityId, roleIds: $roleIds) {
        identityId
      }
    }
  }
`;
