import { Component } from '@angular/core';
import { WebNfcService } from 'src/app/modules/shared/services/web-nfc.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-nfc-player',
  templateUrl: './nfc-player.component.html',
  styleUrl: './nfc-player.component.scss',
})
export class NfcPlayerComponent {
  showNFC = false;
  scanning = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private nfc: WebNfcService) {
    nfc.isNFCActive$.pipe(takeUntil(this.unsubscribe$)).subscribe(isActive => {
      this.showNFC = isActive;
    });
  }

  async scan() {
    this.scanning = true;
    await this.nfc.scanForPlayer();
  }
}
