import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';

/* eslint-disable  @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root',
})
export class SelectedItemsService {
  private renderer!: Renderer2;

  private selectedItems: any[] = [];
  get items(): any[] {
    return this.selectedItems;
  }
  private elementRefs: ElementRef[] = [];

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  add(item: any, elementRef: ElementRef): void {
    if (!this.selectedItems.includes(item)) {
      this.selectedItems.push(item);
      this.renderer.addClass(elementRef, 'selected');
      this.elementRefs.push(elementRef);
    }
  }

  remove(item: any, elementRef: ElementRef) {
    const index = this.selectedItems.indexOf(item);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
      this.renderer.removeClass(elementRef, 'selected');
      this.elementRefs.splice(this.elementRefs.indexOf(elementRef), 1);
    }
  }

  clear() {
    this.elementRefs.forEach(x => {
      this.renderer.removeClass(x, 'selected');
    });
    this.selectedItems = [];
    this.elementRefs = [];
  }
}
