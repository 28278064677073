import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
})
export class ProgressBarComponent {
  protected _progress: number = 0;

  @Input({ required: true })
  set progress(value: number) {
    if (isNaN(value)) {
      value = 0;
    }
    this._progress = value;
  }

  @Input() showProgress = true;
}
