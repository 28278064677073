import { gql } from 'apollo-angular';

export const GET_ROLES = gql`
  query roles(
    $filter: [RoleFilter!]!
    $sort: [RoleSort!]!
    $skip: Int
    $take: Int
  ) {
    permissions {
      roles(filter: $filter, skip: $skip, sort: $sort, take: $take) {
        totalCount
        count
        nodes {
          id
          name
          permissions {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query {
    permissions {
      permissions {
        id
        name
      }
    }
  }
`;

export const ADD_ROLES = gql`
  mutation ($role: RoleCreate!) {
    roles {
      create(role: $role) {
        id
      }
    }
  }
`;

export const EDIT_ROLES = gql`
  mutation ($role: RoleEdit!) {
    roles {
      edit(role: $role) {
        id
      }
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation ($roleId: Int!) {
    roles {
      delete(roleId: $roleId)
    }
  }
`;
