<div *ngIf="headerTranslationKey" class="flex flex-col gap-5">
  <div class="page-header flex space-between">
    <div class="w-full">
      <h1>{{ headerTranslationKey | translate }}</h1>
    </div>
    <div class="flex w-full padding-5 justify-center items-center">
      <ng-content></ng-content>
    </div>
    <div class="flex justify-center items-center">
      <p-button
        class="icon-btn btn w-full"
        icon="pi pi-info-circle"
        (onClick)="op.toggle($event)">
      </p-button>
      <p-overlayPanel #op>
        <div *ngFor="let shortcut of shortcuts" class="flex gap-10">
          <div>
            {{ shortcut.key }}
          </div>
          <div>
            {{ shortcut.description }}
          </div>
        </div>
      </p-overlayPanel>
    </div>
  </div>
  <div *ngIf="breadcrumbs">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbs" [home]="home" />
  </div>
</div>
