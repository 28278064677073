<div class="tile-select">
  <div *ngFor="let item of items">
    <button
      [class]="styleClass + ' bg-transparent'"
      [ngClass]="item.active ? 'tile-active' : 'tile'"
      (click)="selectItem(item)">
      <div class="flex justify-center items-center gap-5">
        <i *ngIf="item.icon" [class]="item.icon"></i>
        <span>{{ item.label }}</span>
      </div>
    </button>
  </div>
</div>
