<div class="flex gap-10">
  <div>
    <p-triStateCheckbox
      [ngModel]="filterOrga"
      inputId="binary"
      (onChange)="filterOrgaChange.emit($event.value)"
      label="{{ 'common.orga' | translate }}" />
  </div>
  <div>
    {{ gameDayInfo.presentPlayers }}
    {{ 'common.of' | translate }}
    {{ gameDayInfo.playerCount }}
    {{ 'attendance_state.PRESENT' | translate }}
  </div>
  <div class="flex gap-5">
    <div *ngFor="let squad of gameDayInfo.squads">
      <span *ngIf="squad?.squad" style="color: {{ squad.squad.colorHex }}"
        >{{ squad.squad.name | FirstLetter }} : {{ squad.playerCount }}</span
      >
    </div>
  </div>
</div>
