<div class="flex gap-10 justify-center items-center">
  <div
    *ngFor="let option of options"
    [ngClass]="value === option.value ? 'chip-active' : 'chip'">
    <p-chip
      label="{{ option.label }}"
      (click)="selectItem(option)"
      [ngClass]="{ disabled: option.disabled }"></p-chip>
  </div>
</div>
